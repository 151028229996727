import 'bootstrap'
import styles from '/assets/scss/app.scss';
import scripts from '/assets/js/app.js';

require('./assets/js/jquery-cookies');
// require('./assets/js/cookies');
require('./assets/js/jquery-global');
require('./assets/js/jquery-template');
require('./assets/js/lwsreviews-feed');
require('./assets/js/vue/app');
require('./assets/js/splash.js');