


const grid = document.getElementById('grid');

if (grid) {
    var pckry = new Packery('.grid', {
        itemSelector: '.grid-item',
        gutter: 20
    });
}

const header = document.getElementById('header');
const burger = document.getElementById('burger');
const burgerTxt = burger.querySelector('p');

burger.addEventListener('click', () => {
    header.classList.toggle('active');
    if (window.location.href.indexOf("/de/") > -1) {
        if (burgerTxt.innerHTML != 'menü') {
            burgerTxt.innerHTML = 'menü'
        } else {
            burgerTxt.innerHTML = 'schliessen'
        }
    } else {
        if (burgerTxt.innerHTML != 'menu') {
            burgerTxt.innerHTML = 'menu'
        } else {
            burgerTxt.innerHTML = 'fermer'
        }
    }

})

window.addEventListener('scroll', () => {
    if (window.scrollY > 100) {
        header.classList.add('tiny');
    } else {
        header.classList.remove('tiny');
    }
})

if (document.querySelector('.hide-more~a')) {
    document.querySelector('.hide-more~a').addEventListener('click', () => {
        document.querySelector('.hide-more').classList.add('show')
    })
}

document.querySelectorAll('.header__nav').forEach((el) => {
    el.addEventListener('click', () => {
        header.classList.toggle('active');
        if (burgerTxt.innerHTML != 'menu') {
            burgerTxt.innerHTML = 'menu'
        } else {
            burgerTxt.innerHTML = 'fermer'
        }
    })
})

//Review injecting

const ratings = document.getElementById('ratings');
if (ratings) {
    setTimeout(() => {
        let googleRating, facebookRating, googleNumber, facebookNumber;
        let data = document.querySelectorAll('.avg-widget-item');
        let targets = document.querySelectorAll('.notes__item');

        if (data[0]) {
            googleRating = data[0].querySelector('.avg-widget-item-ratings').innerHTML;
            googleRating = parseFloat(googleRating).toFixed(1);
            googleNumber = data[0].querySelector('.avg-widget-item-count').innerHTML;
            targets[0].querySelector('.notes__note').innerHTML = googleRating;
            targets[0].querySelector('.notes__end').innerHTML = googleNumber;
        } else {
            targets[0].remove();
        }

        if (data[1]) {
            facebookRating = data[1].querySelector('.avg-widget-item-ratings').innerHTML;
            facebookRating = parseFloat(facebookRating).toFixed(1);
            facebookNumber = data[1].querySelector('.avg-widget-item-count').innerHTML;
            targets[1].querySelector('.notes__note').innerHTML = facebookRating;
            targets[1].querySelector('.notes__end').innerHTML = facebookNumber;
        } else {
            targets[1].remove();
        }

    }, 1000)
}


jQuery(function() {
    $('.tab_content--link').on('click',function(e){
        e.preventDefault();
        $('.tab_content--link').removeClass('active');
        $(this).addClass('active');

        valcontent = $(this).attr('data-tab');
        
        
        $('.tab_content-content').removeClass('active');
        $('.tab_content-content[data-tab="' + valcontent + '"]').addClass('active');
        
        

    });
});