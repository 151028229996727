jQuery(function() {

	var SPLASH_HOME = 'splash-home';

	
	$splash = $.cookie(SPLASH_HOME);
	if ($splash == null) {
        jQuery('.splashtrigger')[0].click()
	}
	$.cookie(SPLASH_HOME, 'done', { path: '/', expires: 6 });
	
}); 